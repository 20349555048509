import { Component, Input, OnInit, OnChanges, inject } from "@angular/core";
import { RssNewsItem } from "../../schemas/rss-news-item";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ParseDatePipe } from "../../pipes/parse-date.pipe";
import { GetRssNewsItemLink } from "../../pipes/get-rss-news-item-link";

@Component({
  selector: "news-item",
  templateUrl: "./news-item.component.html",
  styleUrl: "./news-item.component.scss",
  imports: [CommonModule, RouterModule, ParseDatePipe, TranslateModule, GetRssNewsItemLink],
})
export class NewsItemComponent implements OnChanges, OnInit {
  private title = inject(Title);
  public translate = inject(TranslateService);

  @Input() item!: RssNewsItem;
  @Input() summary: boolean = false;
  @Input() setBrowserTitle: boolean = false;

  public firstImageUrl: string = "";

  Array = Array;

  ngOnInit() {
    this.getFirstImageUrl().then((s) => (this.firstImageUrl = s));
  }

  ngOnChanges() {
    if (this.setBrowserTitle) this.ngOnChangesAsync();
  }

  private async ngOnChangesAsync() {
    this.title.setTitle(this.item.title);
  }

  private async getFirstImageUrl(): Promise<string> {
    const re = /<img[^>]*src="([^"]+)"[^>]*>/;
    const findings = re.exec(this.item["content:encoded"]);
    return findings?.[0] ?? "";
  }
}
