import { Component, Input, OnInit, OnChanges, inject, input } from "@angular/core";
import { RssNewsItem } from "../../schemas/rss-news-item";
import { ENV } from "../../../app.runtime";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ParseDatePipe } from "../../pipes/parse-date.pipe";
import { GetRssNewsItemLink } from "../../pipes/get-rss-news-item-link";

@Component({
  selector: "news-headers",
  templateUrl: "./news-headers-list.component.html",
  imports: [AngularSvgIconModule, CommonModule, TranslateModule, ParseDatePipe, GetRssNewsItemLink],
})
export class NewsHeaders {
  protected translate = inject(TranslateService);
  protected newsItems = input<RssNewsItem[]>([]);

  newsUrl = ENV.NWD_NEWS_URL;
  // @Input() newsItems: RssNewsItem[] = [];
}
