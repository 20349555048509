import { Injectable } from "@angular/core";
import { XMLParser } from "fast-xml-parser";
import { RssNewsList } from "../../schemas/rss-news-list";
import { HttpClient } from "@angular/common/http";
import { ENV } from "../../../app.runtime";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class NewsService {
  private parser = new XMLParser();

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  async getNewsItemsAll(): Promise<{ [key: string]: RssNewsList }> {
    const langs = ["en", "nl"];
    const promises: Promise<RssNewsList>[] = [];
    for (let lang of langs) {
      const feedJson = this.getNewsItemsForLanguage(lang);
      promises.push(feedJson);
    }

    const feeds = await Promise.all(promises);
    return feedsArrToMap(feeds);
  }

  async getNewsItemsForUserLanguage(): Promise<RssNewsList> {
    const userLang = extractLang(localStorage.getItem("lang"));
    const browserLang = extractLang(navigator.language);
    const language =
      userLang ? userLang
      : browserLang ? browserLang
      : "nl";

    return this.getNewsItemsForLanguage(language);
  }

  async getNewsItemsForLanguage(language: string): Promise<RssNewsList> {
    const url = ENV.NEWS_RSS_FEED_URL ? ENV.NEWS_RSS_FEED_URL.replace("/feed/", `/${language}/feed/`) : "";
    const supported = this.translate.getLangs();
    if (!supported.includes(language)) {
      console.warn(`Attempting to use an unexpected language ${language}; expected one of ${supported}.`);
    }
    const feedXml = await this.http.get(url, { responseType: "text" }).toPromise();
    return this.parser.parse(feedXml);
  }
}

const feedsArrToMap = (feeds: RssNewsList[]): { [key: string]: RssNewsList } => {
  const result = {};
  for (let feed of feeds) {
    const lang = (feed.rss.channel.language ?? "").split("-")[0];
    result[lang] = feed;
  }
  return result;
};

const extractLang = (locale: string | undefined) => (locale ?? "").split("-")[0]; // Transform nl-NL to nl
