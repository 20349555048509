export interface RssNewsItem {
  category: string[] | string;
  comments: string;
  "content:encoded": string;
  "dc:creator": string;
  description: string;
  guid: string;
  link: string;
  pubDate: string;
  "slash:comments": number;
  title: string;
  "wfw:commentsRsss": string;
}

export function rssNewsItemLink(item: RssNewsItem): string {
  const guid = parseGuid(item.guid);
  const slug = _slugify(item.title);
  return `${guid}/${slug}`;
}

export function parseGuid(value: string): string {
  const parts = value.split("=");
  const last = parts.length - 1;

  // @ts-ignore
  const isDigit = (s: string) => !isNaN(s);
  if (last < 0 || !isDigit(parts[last])) {
    console.warn(`Not able to extract guid from ${value} at position ${last}; returning empty string`);
    return "";
  }

  return parts[last];
}

function _slugify(input: string): string {
  return input
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}
