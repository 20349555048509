import { Pipe, PipeTransform } from "@angular/core";
import { RssNewsItem, rssNewsItemLink } from "../schemas/rss-news-item";

@Pipe({
  name: "getNewsItemLink",
})
export class GetRssNewsItemLink implements PipeTransform {
  transform(item: RssNewsItem): string {
    return rssNewsItemLink(item);
  }
}
