import { Component } from "@angular/core";
import { NavigationComponent } from "../../../../components/common/navigation/navigation";
import { ENV } from "../../../app.runtime";
import { CommonModule } from "@angular/common";
import { AngularSvgIconModule } from "angular-svg-icon";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "navigation-news",
  templateUrl: "./navigation-news.component.html",
  styleUrls: ["../../../../components/common/navigation/navigation.scss"],
  imports: [AngularSvgIconModule, CommonModule, RouterModule, TranslateModule],
})
export class NavigationNews extends NavigationComponent {
  newsUrl = ENV.NWD_NEWS_URL;
  dashboardUrl = ENV.NWD_DASHBOARD_URL;

  protected switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }
}
