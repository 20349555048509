<navigation-news
  [forceTitle]="
    !!(newsItemId() && news) ?
      ((news?.[lang()] | extractNewsItemsSafely | selectNewsItem: newsItemId())?.title ?? '')
    : ('News.Title' | translate)
  "
  [isChildRoute]="!!(newsItemId() && news)"
></navigation-news>
<div>
  <div class="container">
    <!-- News item view -->
    <ng-container #newsItem *ngIf="!!(newsItemId() && news); else newsFeed">
      <news-item
        [item]="news?.[lang()] | extractNewsItemsSafely | selectNewsItem: newsItemId()"
        [summary]="false"
        [setBrowserTitle]="true"
      ></news-item>
    </ng-container>
    <!-- News feed view -->
    <ng-template #newsFeed>
      <div *ngFor="let item of news?.[lang()] | extractNewsItemsSafely">
        <news-item [item]="item" [summary]="true"></news-item>
      </div>
    </ng-template>
    <!-- End -->
    <footer></footer>
  </div>
</div>
