import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Newspage } from "./pages/news/news";

export const routes: Routes = [
  {
    path: "",
    redirectTo: (navigator.language ?? "nl").split("-")[0],
    pathMatch: "full",
  },
  { path: ":lang", component: Newspage },
  { path: ":lang/:newsItemId", component: Newspage },
  { path: ":lang/:newsItemId/:newsItemTitle", component: Newspage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewsRoutingModule {}
