import { Component, inject, input, Input, OnInit } from "@angular/core";
import { RssNewsList } from "../../schemas/rss-news-list";
import { NewsService } from "../../services/news/news.service";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { NavigationNews } from "../../components/navigation-news/navigation-news.component";
import { NewsItemComponent } from "../../components/news-item/news-item.component";
import { ExtractNewsItemsSafelyPipe } from "../../pipes/extract-news-items.pipe";
import { SelectNewsItemPipe } from "../../pipes/find-news-item.pipe";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "../../../../components/common/footer/footer";

@Component({
  selector: "news-page",
  templateUrl: "./news.html",
  imports: [
    CommonModule,
    NavigationNews,
    NewsItemComponent,
    ExtractNewsItemsSafelyPipe,
    SelectNewsItemPipe,
    TranslateModule,
    FooterComponent,
  ],
})
export class Newspage implements OnInit {
  protected lang = input<string>(undefined);
  protected newsItemId = input<string>(undefined);

  private router = inject(Router);
  private newsService = inject(NewsService);
  private title = inject(Title);
  private translate = inject(TranslateService);

  protected news: { [key: string]: RssNewsList } | undefined;

  ngOnInit() {
    this.ngOnInitAsync();
  }

  navigateToLanguage = (lang: string) => this.router.navigate(["news", lang]);

  private async ngOnInitAsync() {
    if (!this.translate.langs.includes(this.lang())) {
      console.warn("Rerouting to 'nl'");
      this.navigateToLanguage("nl");
    }

    this.news = await this.newsService.getNewsItemsAll();
    const newsTranslation = await this.translate
      .get("Navigation.News")
      .toPromise()
      .catch(() => "Nieuws");
    this.title.setTitle(`SURFnet ${newsTranslation.toLowerCase()}`);
  }
}
