<div class="card">
  <ng-template #contentTemplate>
    <div class="service__title-wrapper">
      <h3 class="service__title" [innerHTML]="item.title"></h3>
    </div>
    <div class="service__subtitle">
      <span>{{ item.pubDate | parseDate | date: "d MMMM YYYY" : undefined : translate.currentLang }}</span
      >&nbsp;&mdash;&nbsp;<span>{{ item["dc:creator"] }}</span>
    </div>
    <div class="service__content">
      <p *ngIf="summary" [innerHTML]="item.description"></p>
    </div>
    <div *ngIf="!summary" [innerHTML]="item['content:encoded']"></div>
    <div class="tags">
      <ng-container *ngIf="Array.isArray(item.category); else oneTag" #multipleTags>
        <ng-container *ngFor="let tag of item.category">
          <div *ngIf="tag !== 'Uncategorized'" class="tag">{{ tag }}</div>
        </ng-container>
      </ng-container>
      <ng-template #oneTag>
        <div *ngIf="item.category !== 'Uncategorized'" class="tag">
          {{ item.category }}
        </div>
      </ng-template>
    </div>
  </ng-template>

  <!-- Wrap the content in a clickable link, if a summary-->
  <a
    *ngIf="summary; else contentTemplate"
    class="no-style-link"
    style="display: flex; gap: 16px"
    [routerLink]="[item | getNewsItemLink]"
  >
    <div style="display: flex; flex-direction: column">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div
      *ngIf="firstImageUrl"
      style="display: flex; flex-direction: column; width: 600px"
      [innerHTML]="firstImageUrl"
    ></div>
  </a>
</div>
